import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
export default function VolunteerRating(){
    return(
        <>
            <Header />
                <div className="userName">רמי, בוקר טוב</div>
                <section className="mainAppContent">
                    <h2 className="headingAnother">דירוג מתנדבים</h2>
                    <div className="ratingbyBeneficiaries">
                        <h3>דירוג מתנדבים ע”י הנעזרים</h3>
                        <div className="rbBox">
                            <h4>המתנדב רפאל</h4>
                            <div className="starsBox">
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                            </div>
                            <div className="reviewsBox">
                                <h5>20 ביקורות - 4.9</h5>
                                <div className="helpers">
                                    דירוג ע”י 20 נעזרים 
                                </div>
                            </div>
                        </div>
                        <div className="rbBox">
                            <h4>המתנדב רפאל</h4>
                            <div className="starsBox">
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                            </div>
                            <div className="reviewsBox">
                                <h5>20 ביקורות - 4.9</h5>
                                <div className="helpers">
                                    דירוג ע”י 20 נעזרים 
                                </div>
                            </div>
                        </div>
                        <div className="rbBox">
                            <h4>המתנדב רפאל</h4>
                            <div className="starsBox">
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                            </div>
                            <div className="reviewsBox">
                                <h5>20 ביקורות - 4.9</h5>
                                <div className="helpers">
                                    דירוג ע”י 20 נעזרים 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}