import Header from "../components/Header";
import crackers from '../assets/images/crackrs.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Login(){
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const [userNameClass, setUserNameClass] = useState('logInpt');
    const [passwordClass, setPasswordClass] = useState('logInpt pass');
    const navigate = useNavigate();

    function submit(){
        if(userName === '') { 
            setUserNameClass('logInpt invalid');
        }
        if(password === '') { 
            setPasswordClass('logInpt pass invalid');
        }
        if(userName && password !== '') {
            navigate('/ChooseField');
        }
    }

    function typing(){
        if(userName !== '') { 
            setUserNameClass('logInpt');
        }
        if(password !== '') { 
            setPasswordClass('logInpt pass');
        }
    }

    return(
        <>
            <Header />
            <section className="loginContainer">
                <h2>התחבר לחשבונך</h2>
                <picture><img src={crackers} alt="" /></picture>
                <div className="loginBox">
                    <input type="email" className={userNameClass} value={userName} onChange={(e)=>setUserName(e.target.value)} onKeyUp={typing} placeholder="הכנס את האימייל שלך" />
                    <input type="password" className={passwordClass} value={password} onChange={(e)=>setPassword(e.target.value)} onKeyUp={typing} placeholder="מלא את הסיסמה" />
                    <div className="rememberBox">
                        <Link>שכחת סיסמה?</Link>
                        <label className="rember">
                            <input type="checkbox" />
                            <span className="checkMark"><FontAwesomeIcon icon={faCheck} /></span>
                            <b>זכור אותי</b>
                        </label>
                    </div>
                    <button className="subBttn" onClick={submit}>התחבר</button>
                    <Link className="loginGoogle"><FontAwesomeIcon icon={faGooglePlusG} /> התחבר עם Google</Link>
                    <p>עדיין אין לך חשבון? <Link to='/'>הרשם</Link></p>                    
                </div>
            </section>
        </>
    )
}