import { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
export default function VolunteerDiary() {
    const [startTime, setStartTime] = useState('00:00');
    const [tillTime, setTillTime] = useState('00:00');
    return(
        <>
        <Header />
            <div className="userName">רמי, בוקר טוב</div>
            <section className="mainAppContent">
                <h2 className="headingAnother">יומן התנדבות</h2>
                <div className="formWrap anotherFormStyle">
                    <form action="">
                        <div className="roleWrap">
                            <h3>כמה זמן בשבוע אתה יכול להקדיש</h3>
                            <div className="roleBoxesWrap">
                                <div className="radioBoxesWrap">

                                    <label className="lebBoxRadio">
                                        <input type="radio" name="radio" />
                                        <span className="checkMark"><p></p></span>
                                        <b>דקות</b>
                                    </label>

                                    <label className="lebBoxRadio">
                                        <input type="radio" name="radio" />
                                        <span className="checkMark"><p></p></span>
                                        <b>שעות</b>
                                    </label>

                                </div>
                            </div>
                        </div>
                        <div className="roleWrap selectDays">
                            <h3>באיזה ימים בשבוע נוח לך?</h3>
                            <div className="selectDaysBlock">
                                <label className="dayBox">
                                    <input type="checkbox" className="checkBox" />
                                    <b>א</b>
                                    <span className="checkMark"></span>
                                </label>
                                <label className="dayBox">
                                    <input type="checkbox" className="checkBox" />
                                    <b>ב</b>
                                    <span className="checkMark"></span>
                                </label>
                                <label className="dayBox">
                                    <input type="checkbox" className="checkBox" />
                                    <b>ג</b>
                                    <span className="checkMark"></span>
                                </label>
                                <label className="dayBox">
                                    <input type="checkbox" className="checkBox" />
                                    <b>ד</b>
                                    <span className="checkMark"></span>
                                </label>
                                <label className="dayBox">
                                    <input type="checkbox" className="checkBox" />
                                    <b>ה</b>
                                    <span className="checkMark"></span>
                                </label>
                                <label className="dayBox">
                                    <input type="checkbox" className="checkBox" />
                                    <b>ו</b>
                                    <span className="checkMark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="roleWrap selectHours">
                            <h3>באיזה שעות נוח לך?</h3>
                            <label className="selectHour">
                                <b>משעה</b>
                                <input type="time" value={startTime} onChange={(e)=>setStartTime(e.target.value)} step="900" className='setTime' />
                            </label>
                            <label className="selectHour">
                                <b>עד שעה</b>
                                <input type="time" value={tillTime} onChange={(e)=>setTillTime(e.target.value)} className='setTime' />
                            </label>
                        </div>
                        <div className="roleWrap subBttns">
                        <input type="reset" className="buttons" value='איפוס' />
                            <input type="submit" className="submit buttons" value='אישור' />
                        </div>
                    </form>
                </div>
            </section>
        <Footer />
        </>
    )
}