import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import icon_01 from '../assets/images/icon01.svg'
import icon_02 from '../assets/images/icon02.svg'
import icon_03 from '../assets/images/icon03.svg'
import icon_04 from '../assets/images/icon04.svg'
import icon_05 from '../assets/images/icon05.svg'
import icon_06 from '../assets/images/icon06.svg'
import icon_07 from '../assets/images/icon07.svg'
import icon_08 from '../assets/images/icon08.svg'
import icon_09 from '../assets/images/icon09.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useRef } from "react";
export default function ChooseAnotherField(){

    const ref = useRef(null);
    const removeSelected =()=> {
        ref.current.classList.remove('selected');
        ref.current.classList.add('notSelected');
    }
    return(
        <>
        <Header />
            <div className="userName">רמי, בוקר טוב</div>
            <section className="mainAppContent anotherContain">
                <h2>האם תרצה לבחור תחום נוסף?</h2>
                <div className="experianceWrap">
                    <Link className="expBox selected" ref={ref}>
                        <img src={icon_01} alt="" />
                        <b><FontAwesomeIcon icon={faCheck} /></b>
                        <button onClick={removeSelected}><FontAwesomeIcon icon={faTimes} /></button>
                        <span> ניסיון צבאי</span>
                    </Link>
                    <Link className="expBox" to='/Questionnaire'>
                        <img src={icon_02} alt="" />
                        <span>ניסיון תעסוקתי</span>
                    </Link>
                    <Link className="expBox" to='/Questionnaire'>
                        <img src={icon_03} alt="" />
                        <span>לימודים</span>
                    </Link>
                    <Link className="expBox" to='/Questionnaire'>
                        <img src={icon_04} alt="" />
                        <span>כושר ותזונה</span>
                    </Link>
                    <Link className="expBox" to='/Questionnaire'>
                        <img src={icon_05} alt="" />
                        <span>בריאות</span>
                    </Link>
                    <Link className="expBox" to='/Questionnaire'>
                        <img src={icon_06} alt="" />
                        <span>מצב נפשי</span>
                    </Link>
                    <Link className="expBox" to='/Questionnaire'>
                        <img src={icon_07} alt="" />
                        <span>תחביבים וטיולים</span>
                    </Link>
                    <Link className="expBox" to='/Questionnaire'>
                        <img src={icon_08} alt="" />
                        <span>פיננסים</span>
                    </Link>
                    <Link className="expBox" to='/Questionnaire'>
                        <img src={icon_09} alt="" />
                        <span>אחר</span>
                    </Link>
                </div>
            </section>
            <div className="vdButtonWrap">
                <Link className="vdButton">למעבר ליומן התנדבות &gt;</Link>
            </div>
        <Footer />
        </>
    )
}