import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ChooseField from "./pages/ChooseField";
import Questionnaire from "./pages/Questionnaire";
import './assets/css/style.css'
import ChooseAnotherField from "./pages/ChooseAnotherField";
import VolunteerDiary from "./pages/VolunteerDiary";
import ThankYou from "./pages/ThankYou";
import VolunteerConsultant from "./pages/VolunteerConsultant";
import VolunteerRating from "./pages/VolunteerRating";
import PersonalArea from "./pages/PersonalArea";
function App() {
  return (
    <div className="completeAppWrap">    
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/ChooseField" element={<ChooseField />} />
        <Route path="/Questionnaire" element={<Questionnaire />} />
        <Route path="/ChooseAnotherField" element={<ChooseAnotherField />} />
        <Route path="/VolunteerDiary" element={<VolunteerDiary />} />
        <Route path="/ThankYou" element={<ThankYou />} />
        <Route path="/VolunteerConsultant" element={<VolunteerConsultant />} />
        <Route path="/VolunteerRating" element={<VolunteerRating />} />
        <Route path="/PersonalArea" element={<PersonalArea />} />
      </Routes>
    </div>
  );
}

export default App;
