import Header from "../components/Header";
import Footer from "../components/Footer";
export default function PersonalArea() {
    return(
        <>
            <Header />
                <div className="userName">רמי, בוקר טוב</div>
                <section className="mainAppContent">
                    <h2 className="headingAnother">
                        איזור אישי - פגישות <br />
                        ממתינות לאישור
                    </h2>
                    <div className="personalBoxesTotalWrap">
                        <h3>רמי, אלו הפגישות שלך</h3>
                        <div className="meatingBoxWrap">

                            <div className="meetBox">
                                <div className="meetContents">
                                    <div className="mbContent">
                                        <h4>נקבעה פגישה עם משה </h4>
                                        <h4>בתאריך 1/2/24</h4>
                                        <h4>בשעה 13:00</h4>
                                        <h4>בנושא: תעסוקה</h4>
                                    </div>
                                    <div className="mbButtons">
                                        <button>שינוי זמן</button>
                                        <button className="confirmation">אישור</button>
                                    </div>
                                </div>
                                <div className="meetReason">הפגישה לזכרו של ישראל</div>
                            </div>

                            <div className="meetBox">
                                <div className="meetContents">
                                    <div className="mbContent">
                                        <h4>נקבעה פגישה עם משה </h4>
                                        <h4>בתאריך 1/2/24</h4>
                                        <h4>בשעה 13:00</h4>
                                        <h4>בנושא: תעסוקה</h4>
                                    </div>
                                    <div className="mbButtons">
                                        <button>שינוי זמן</button>
                                        <button className="confirmation">אישור</button>
                                    </div>
                                </div>
                                <div className="meetReason">הפגישה לזכרו של ישראל</div>
                            </div>

                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}