import Logo from '../assets/images/Logo.svg';
import MenuBar from '../assets/images/menuBar.svg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
export default function Header(){
    const animationRef = useRef(null);
    const toggleIcon = () => {
        animationRef.current.classList.toggle('show');
    };
    return(
        <>
            <header>
                <span className="logo"><img src={Logo} alt="" /></span>
                <span className="menuBar" onClick={toggleIcon}><img src={MenuBar} alt="" /></span>
            </header>
            <aside ref={animationRef} className='sideBar'>
                <span className="toggleIcon" onClick={toggleIcon}><FontAwesomeIcon icon={faTimes} /></span>
                <ul className="appNav">
                    <li><Link>עמוד הבית</Link></li>
                    <li><Link>אודות</Link></li>
                    <li><Link>חפש יועץ</Link></li>
                    <li><Link>הרשם להתנדבות</Link></li>
                    <li><Link>איזור אישי</Link></li>
                    <li><Link>תקנון</Link></li>
                    <li><Link>צור קשר</Link></li>
                    <li><Link>ניתוק/התחבר</Link></li>
                    <li><Link>מחק חשבון</Link></li>
                </ul>
                <button>Click here</button>
            </aside>
        </>
    )
}