import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
export default function Questionnaire(){
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
      };
    return(
        <>
            <Header />
                <div className="userName">רמי, בוקר טוב</div>
                <section className="mainAppContent">
                    <h2> שאלון - ניסיון צבאי</h2>
                    <h3>התחומים שבהם יש לך ניסיון</h3>
                    <div className="formWrap">
                        <form action="">
                            <div className="chooseExperiance">
                                <label className="expBox">
                                    <input type="checkbox" />
                                    <span className="checkMark">חיל הים</span>
                                </label>
                                <label className="expBox">
                                    <input type="checkbox" />
                                    <span className="checkMark">חיל האוויר</span>
                                </label>
                                <label className="expBox">
                                    <input type="checkbox" />
                                    <span className="checkMark">חיל המודיעין</span>
                                </label>
                                <label className="expBox">
                                    <input type="checkbox" />
                                    <span className="checkMark">חיל היבשה</span>
                                </label>
                                <label className="expBox">
                                    <input type="checkbox" />
                                    <span className="checkMark">חיל כללי</span>
                                </label>
                            </div>
                            <div className="roleWrap">
                                <h3>איזה תפקיד מילאת?</h3>
                                <div className="roleBoxesWrap">
                                    <label className="lebBox">
                                        <input type="checkbox" />
                                        <span className="checkMark"><FontAwesomeIcon icon={faCheck} /></span>
                                        <b>פיקודי</b>
                                    </label>
                                    <label className="lebBox">
                                        <input type="checkbox" />
                                        <span className="checkMark"><FontAwesomeIcon icon={faCheck} /></span>
                                        <b>תומך לחימה</b>
                                    </label>
                                    <label className="lebBox">
                                        <input type="checkbox" />
                                        <span className="checkMark"><FontAwesomeIcon icon={faCheck} /></span>
                                        <b>מקצועי</b>
                                    </label>
                                    <label className="lebBox">
                                        <input type="checkbox" onChange={handleCheckboxChange} checked={isChecked} />
                                        <span className="checkMark"><FontAwesomeIcon icon={faCheck} /></span>
                                        <b>חייל בודד</b>
                                    </label>

                                    {isChecked  && (
                                        <div className="radioBoxesWrap">

                                            <label className="lebBoxRadio">
                                                <input type="radio" name="radio" />
                                                <span className="checkMark"><p></p></span>
                                                <b>לוחם</b>
                                            </label>

                                            <label className="lebBoxRadio">
                                                <input type="radio" name="radio" />
                                                <span className="checkMark"><p></p></span>
                                                <b>ישראלי</b>
                                            </label>

                                            <label className="lebBoxRadio">
                                                <input type="radio" name="radio" />
                                                <span className="checkMark"><p></p></span>
                                                <b>קצין</b>
                                            </label>

                                            <label className="lebBoxRadio">
                                                <input type="radio" name="radio" />
                                                <span className="checkMark"><p></p></span>
                                                <b>חרדי</b>
                                            </label>

                                            <label className="lebBoxRadio">
                                                <input type="radio" name="radio" />
                                                <span className="checkMark"><p></p></span>
                                                <b>אחר</b>
                                            </label>

                                            <label className="lebBoxRadio">
                                                <input type="radio" name="radio" />
                                                <span className="checkMark"><p></p></span>
                                                <b>עולה</b>
                                            </label>

                                        </div>
                                    )}
                                    
                                </div>
                            </div>
                            <div className="personalFilling">
                                <p>על מנת שנוכל למצוא את ההתאמה הטובה ביותר, נשמח אם תכתוב קצת על עצמך...</p>
                                <textarea placeholder="מילוי אישי...." className="textBox"></textarea>
                            </div>
                            <input type="submit" value='סיים' className="subBtn" />
                        </form>
                    </div>
                </section>
            <Footer />
        </>
    )
}