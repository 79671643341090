import solgerImg from '../assets/images/solgerImg.svg'
export default function Footer(){
    return(
        <>
            <footer>
                <span className="volen">
                    <h3>התנדבויות מובילות</h3>
                    <p>ירושלים 20</p>
                    <p>אשדוד 18</p>
                    <p>באר שבע 12</p>
                </span>
                <span className="commerote">
                    <picture><img src={solgerImg} alt="" /></picture>
                    <h3>
                        <small>מנציחים היום את:</small>
                        סמ"ר נועם יוסף אבו
                        <small>מנציחים היום את:</small>
                    </h3>
                </span>
            </footer>
        </>
    )
}