import Header from "../components/Header";
import Footer from "../components/Footer";
import hand from '../assets/images/shakeHand.png';
import { Link } from "react-router-dom";
export default function ThankYou(){
    return (
        <>
            <Header />
            <section className="mainAppContent">
                <h2 className="headingAnother">תודה שאתה לוקח חלק בהנצחת נופלי מלחמת חרבות הברזל דרך התנדבות ב”TOGETHER”</h2>
                <div className="shakeHandPic"><img src={hand} alt="" /></div>
                <Link className="adviceButton">מעוניין לקבל ייעוץ?</Link>
            </section>
            <Footer />
        </>
    )
}