import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
export default function VolunteerConsultant(){
    return(
        <>
            <Header />
                <div className="userName">רמי, בוקר טוב</div>
                <section className="mainAppContent">
                    <h2 className="headingAnother">חיפוש יועץ מתנדב</h2>
                    <div className="matchesBosesWrap">
                        <h3>נמצאו התאמות</h3>
                        <div className="mBox">
                            <div className="reviewBox">
                                <h4>יוסי לוי</h4>
                                <div className="stars">
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                </div>
                                <div className="rating">20 ביקורות - 4.9</div>
                                <div className="helpersRating">דירוג ע”י 20 נעזרים</div>
                            </div>
                            <Link className="appoinmentBttn">לקביעת פגישה</Link>
                        </div>
                        <div className="mBox">
                            <div className="reviewBox">
                                <h4>יוסי לוי</h4>
                                <div className="stars">
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                </div>
                                <div className="rating">20 ביקורות - 4.9</div>
                                <div className="helpersRating">דירוג ע”י 20 נעזרים</div>
                            </div>
                            <Link className="appoinmentBttn">לקביעת פגישה</Link>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}